import React from 'react';
import { Link } from 'react-router-dom'
import Parser from 'html-react-parser';
const CategoriesSingleTwo = (props) => {
    const { categoriesClass, iconImg, catLink, title, courseQuantity,date } = props;

    return (
        <a className={categoriesClass ? categoriesClass : 'categories-item'} target ={catLink ? '_blank' : ''} href={catLink ? catLink : ''}>
            <div className="col-lg-12 col-md-6 mb-30">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-2'>
                            {/* <div className='row'>
                                <div className='col-md-12'> */}
                                    <div className="icon-part">
                                        <img
                                            src={iconImg}
                                            alt={title}
                                        />
                                    </div>
                                {/* </div>
                                <div className='col-md-12'> */}
                                {
                                    // (date)?(
                                    //     <p className="">{date}</p>
                                    // ):''
                                }
                                {/* </div>
                            </div> */}
                            
                            
                        </div>
                        <div className='col-md-10'>
                            <div className="content-part">
                                <h4 className="title">{title ? title : 'General Education'}</h4>
                                <span className="courses">{courseQuantity ? Parser(courseQuantity) : ''} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default CategoriesSingleTwo