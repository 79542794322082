import React,{useState,useEffect} from 'react'

const CourseStructure = (props) => {

    const [courseData,setCourseData] = useState();
    const course = props.course;

    useEffect( () => {

        if(course){
            setCourseData(course);
        }
        
    },[]);

    console.log(courseData);

    if(courseData){

        return (
            <>
                <h5>Course Structure</h5>
                <hr style={{marginTop:"-10px"}} />
                
                <a href={courseData.courseStructure} target="blank" className='btn btn-success' style={{fontSize:'12px',backgroundColor:'green'}}>Download</a>
                <a href={courseData.courseStructureNew} target="blank" style={{paddingLeft:'20px'}} >New Structure</a>

                <h5 style={{marginTop:'38px'}}>Course Syllabus</h5>
                <hr style={{marginTop:"-10px"}} />
                <a href={courseData.courseSyllabus} target="blank" className='btn btn-success' style={{fontSize:'12px',backgroundColor:'green'}}>Download</a>
                <a href={courseData.courseSyllabusNew} target="blank" style={{paddingLeft:'20px'}} >New syllabus</a>
            </>
        )

    }else{
        return(
            <></>
        )
    }
    
}

export default CourseStructure