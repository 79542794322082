import React,{useState,useEffect} from 'react'

import icon1 from '../../assets/img/categories/icons/1.png';
import SectionTitle from '../../components/Common/SectionTitle';
import CategoriesSingleTwo from '../notice/CategoriesSingleTwo';

import Notification from '../../components/notification/';
import Api from '../../config/Api';
import {  useLocation } from 'react-router-dom';
import {useParams} from 'react-router-dom';


const TextData = () => {

    const[document,setDocument] = useState([]);
    const location = useLocation();

    const {id} = useParams();

    useEffect( ()=>{

        try{
            let formData = new FormData();
            formData.append('document_type',location.pathname)

            Api.post('home/document_list',formData).then( res => {

                let response = res.data;
                
                if(response.status == 1 )
                    setDocument(response.oData.document);

            });

        }catch(error){

            console.log(error);

        }

    },[location.pathname]);
    
    console.log(id);

    return (
        
        <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
            <div className="container">
                <div className="row y-middle mb-50 md-mb-30">
                    <div className="col-md-6 sm-mb-30">
                        <SectionTitle
                            sectionClass="sec-title"
                            subtitleClass="sub-title primary"
                            subtitle="Placement notices"
                            titleClass="title mb-0"
                            // title="Our Top Categories"
                        />
                    </div>
                    <div className="col-md-6">
                        {/* <div className="btn-part text-right sm-text-left">
                            <Link to="course" className="readon">View All Categories</Link>
                        </div> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <Notification/>
                    </div>
                    <div className="col-md-8">

                        {
                            document.map( (d,key) => {

                                return(
                                    <CategoriesSingleTwo
                                        categoriesClass="categories-item"
                                        title={d.docTitle}
                                        quantity=""
                                        iconImg={icon1}
                                        courseQuantity = {d.docCreatedTimestamp}
                                        date   = '2022-10-18'
                                        catLink = {d.docFile}
                                        key = {key}
                                    />  
                                )

                            })
                        }

                        
                        
                    </div>

                </div>
            
            </div>
        </div>
    )
}

export default TextData