import React from 'react'

import SectionTitle from '../../components/Common/SectionTitle';
// About Image
import AboutImage from '../../assets/img/about/about2orange.png';
import FeatureStyle1 from '../../components/Feature/FeatureStyle1';

const CyberTextSection = () => {
    
    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">    
            <div className="rs-slider main-home">
                <FeatureStyle1/>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    {/* <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={AboutImage} alt="About Image" />
                        </div>
                    </div> */}
                    <div className="col-lg-12 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="Cyber Defense"
                                titleClass="title mb-16"
                                title="Welcome to Post Graduate Diploma In Cyber Defense"
                                descClass="bold-text mb-22"
                                description=""
                                secondDescClass="desc"
                                secondDescription="Cyber threats are affecting everyone across the globe and India is no 
                                exception. That's why marshaling whole-of-nation efforts to build Cyber 
                                Battle-Ready resources in large numbers to confront cyber threats effectively 
                                is the need of thehour. Nothing can be more opportune time than now to build
                                a million Cyber Rakshak. It is a matter of utmost pleasure that Vinoba Bhave University (VBU) in 
                                association with and Cybervidyapeeth Foundation is launching the need-of-the 
                                hour course 'Post Graduate Diploma in Cyber Defence (Cyber RakshakAdvance)' 
                                when Cyber Offenders are posing humongous challenges. The well-timed step taken by VBU and Cybervidyapeeth Foundation in this 
                                derection absolutely commendable The Cyber Rakshak-Advance program will surely produce the necessary talent 
                                pool with deep expertise in Cyber Offense and Defense capability. I am sure these
                                Cyber Battle-Ready Talent pool will be an important asset for the country as a 
                                whole. I congratulate Cybervidyapeeth Foundation for their unique endeavor to create 
                                next-generation Cyber Battle-Ready Cyber Rakshak. My best wishes for launching
                                this first-of-its-kind program at VBU in Cyber Defence and incessant effort
                                towards Cyber Resilient & Aatmanirbhar Bharat.
                                "
                            />
                            {/* Section Title End */}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default CyberTextSection