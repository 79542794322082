import React,{useEffect,useState} from 'react'

import SectionTitle from '../../components/Common/SectionTitle';
import Api from '../../config/Api';
import {useParams} from 'react-router-dom';
import SiteBreadcrumb from '../../components/Common/Breadcumb'; 
import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

import LeftSIde from './LeftSIde';
import RightSide from './RightSide';
import '../../assets/custome.css';

const TextData = (props) => {

    const {id} = useParams();
    const[department,setDepartment] = useState();
    const[staffList,setStaffList]   = useState([]);
     
    useEffect( ()=>{
        
        try{
            let formData = new FormData();
            formData.append('uid',id);
            Api.post('home/department',formData).then( res => {
                const response = res.data;
                
                if(response.status == 1 ){

                    setDepartment(response.oData.department);   
                    setStaffList(response.oData.staff_list);

                }
                    
            });

        }catch(error){
            console.log(error);
        }

    },id);
    let pageName = "Departments // ";
    pageName += (department)?department.departmentName:'';

    return (
        <>
            
            <SiteBreadcrumb
                pageTitle="Our Departments"
                pageName={pageName}
                breadcrumbsImg={bannerbg}
            />

            <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
                    <div className="container">
                        <div className="row ">
                            {
                                (department)?
                                    <>
                                        <LeftSIde departmentData = {department} />
                                        <RightSide departmentData = {department} />
                                    </>
                                : ''
                            }
                            

                        </div>
                        {
                            (staffList.length > 0 )?(

                                <div className='row'>
                                    
                                    {
                                        staffList.map( (staff,key) => {

                                            return(
                                                <div className='col-md-9 mt-10' key={key}>

                                                    <div className='card' style={{padding :'15px', borderRadius : '5px'}}>
                                                        <div className='row'>
                                                            <div className='col-md-2 '>
                                                                <img src={staff.staffPic} className='staff-preview' />
                                                            </div>
                                                            <div  className='col-md-3 mt-10'>
                                                                {staff.staffName}
                                                            </div>
                                                            <div className='col-md-2 mt-10'>
                                                                {staff.staffQualification}	
                                                            </div>
                                                            <div className='col-md-2 mt-10'>
                                                                {staff.staffEmail}
                                                            </div>
                                                            <div className='col-md-2 mt-10'>
                                                                {staff.staffMobile}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }


                                </div>
                                
                            ):''
                        }
                        
                    </div>
                </div>
        </>
    )
}

export default TextData