import React from 'react';

import SectionTitle from '../../components/Common/SectionTitle';

// About Image
// import AboutImage from '../../assets/img/about/about2orange.png';
import AboutImage from '../../assets/img/about-us/about-us-1.jpg'

const AboutText = (props) => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={AboutImage} alt="About Image" />
                        </div>
                    </div>
                    <div className="col-lg-6 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="About UCET"
                                titleClass="title mb-16"
                                title=""
                                descClass="mb-22" //bold-text
                                description="UCET is housed in a separate massive three storeyed building (Vishweshraiya Bhawan) in the University Campus itself. It has state of the art laboratories of Computer, Electronics, Mechanical Engineering, Engineering Drawing, Physics, Chemistry, Electrical Machine lab etc."
                                secondDescClass="desc"
                                secondDescription="A Separate workshop of the college has been constructed in the campus, which is fully equipped with the machines, other infrastructure & generator. Learned and experienced faculties have been appointed in all the branches. Sufficient number of supporting staff has also been appointed for smooth functioning of the college. Facilities of latest teaching aid and technology are made available. Moreover UCET has digital campus with internet & wi-fi facilities and has a rich library."
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutText;