import React from 'react';
import { Link } from 'react-router-dom';

const CourseSingleTwo = (props) => {
    const { courseClass, courseImg, courseTitle, catLink, qualification, courseCategory, mailId, userPhone,departmentUID } = props;
    return (
        <div className={courseClass ? courseClass : 'courses-item'}>
            <div className="img-part" style={{height:"380px"}}>
                {
                    (courseImg)?(
                        <Link to = {catLink} >
                            <img
                                src={courseImg}
                                alt={courseTitle}
                                style={{height:"380px"}}
                            />
                        </Link>
                    ):''
                }
               
            </div>
            <div className="content-part">
                <ul className="meta-part"> 
                    {/* <li><span className="price">{coursePrice ? coursePrice : '55.00'}</span></li> */}
                    <li><Link className="categorie" to={departmentUID ? '/department/'+departmentUID : '#'}>{courseCategory ? courseCategory : ''}</Link></li>
                </ul>
                <h3 className="title"><Link to={catLink}>{courseTitle ? courseTitle : ''}</Link></h3>
                <p>{qualification}</p>
                <div className="bottom-part">
                    {/* <div className="info-meta"> */}

                            <Link to ={catLink}><i className="fa fa-envelope"></i> {mailId}</Link>
                            <Link to ={catLink} style={{float:'right'}}><i className="fa fa-phone"></i> {userPhone} </Link>
                        
                    {/* </div> */}
                    {/* <div >
                        <ul>
                            <li className="user"><i className="fa fa-user"></i> {userCount ? userCount : '245'}</li>
                            <li className="ratings">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span>({userRating ? userRating : '05'})</span>
                            </li>
                        </ul>
                    </div> */}
                    {/* <div className="btn-part">
                        <Link to="/course/course-single">
                            {props.btnText}<i className="flaticon-right-arrow"></i>
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default CourseSingleTwo