import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import ComplaintForm from '../../components/Complaint/ComplaintForm';


const TextData = () => {

    return (
        <React.Fragment>

            {/* Contact Section Start */}
            <div className="rs-contact style2 pt-110 md-pt-80 pb-110 md-pb-80">
                <div className="container">
                    <div className="contact-widget event-bg">
                        <SectionTitle
                            sectionClass="sec-title text-center mb-50"
                            titleClass="title black-color mb-14"
                            title="Students Complaint Register Form"
                            descClass="desc big"
                            description=""
                        />

                        {/* Contact Form */}
                        <ComplaintForm
                            submitBtnClass="btn-send"
                            btnText="Submit"
                        />
                        {/* Contact Form */}
                    </div>
                </div>
            </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default TextData;