import React from 'react'
import SectionTitle from '../../components/Common/SectionTitle';
import AboutImage from '../../assets/img/about-us/about-us-1.jpg'
import Mukul from '../../assets/img/vice-chancellor/mukul.jpg'

const TextData = () => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-lg-10 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="Message from Director"
                                titleClass="title mb-16"
                                title=""
                                descClass="mb-22" //bold-text
                                description="We are living in revolutionary times, a revolution brought on by dramatic advances in information Technology. If the steam engine, a new form of power and mechanization created an Industrial revolution over 150 year ago, computers and communications equipment have produced a technology revolution in the last half of the Twentieth century. Specifically speaking culture, business, economy, rules and regulations, customer preferences etc. have become so dynamic that we are in need of extra efforts all the time to cope up with them. In every sphere of life, we are finding competition and for achieving our goals earnest efforts in the right direction is inevitable. Therefore, we at University College of Engineering and Technology (UCET) are designing and maintaining the environment to accomplish the needful. We are emphasizing on providing quality technical education developing communication abilities, overall personality development of students and keeping up-to-date with the technology. To sum of, with our efforts, we are not teaching the students but we are making them learn all the techniques of learning which is actually required to sustain in the current environment. On the other end, we are promoting our students to participate in cultural and social activities. So that they could learn social responsibilities as well. Furthermore, we encourage our faculty members for higher studies and research work. This is them competent in the current scenario. In totally, we are moving ahead with our endeavour to achieve the organization goal to utilize the potential of every individual for their brighter future professional pursuit."
                                secondDescClass="desc"
                                secondDescription=""
                                signature = "Thanks & Regards, <br/>(Dr. A. K. Saha) <br/> Director <br/> Vinoba Bhave University <br/> Hazaribag-825301"
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                    <div className="col-lg-2 mt--24 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        {/* <div className="img-part"> */}
                            {/* <img className="about-main" src={Mukul} alt="About Image" /> */}
                        {/* </div> */}
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500} }>
                            Dr. A. K. Saha
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500,color:'#6c8391'} }>
                            E-Mail : Nill<br/>
                            Mob: Nill
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TextData