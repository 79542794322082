
import React,{useEffect,useState} from 'react'
import CourseStructure from './CourseStructure';
import {useParams} from 'react-router-dom';

import Hod from './Hod';
// import { set } from 'core-js/core/dict';

const RightSide = (props) => {

    const {PKDepartmentID,course,hod} = props.departmentData;
    console.log(hod);

    return (
        <>
            <div className="col-lg-3  order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                <Hod hod ={hod}/>
                <CourseStructure course = {course}/> 
            </div>
            
        </>
    )

    
}

export default RightSide