import React,{useEffect,useState} from 'react'
import Api from '../config/Api'
import { Link } from 'react-router-dom';

const ScrollNews = () => {

    const [newsList,setNewsList] = useState([]);

    useEffect( () => {

        Api.post('home/get_home_news').then( res => {

            const response = res.data;
            setNewsList(response.oData.news_list);

        });

    },[]);

    if(newsList.length == 0 )
        return false;

    return (
        <div className='news-container'>
            
            <ul>
                {
                    newsList.map( (news,key) => {
                        return(
                            <li key ={key}>
                                <Link to={"/news/"+news.PKNewsID} className='news-link'>{news.newsTitle}</Link>
                            </li>
                        )
                    })
                }
                
                
            </ul>
            <div className='news-title'>
                LATEST NEWS
            </div>
        </div>
    )
}

export default ScrollNews