import React,{useEffect,useState} from 'react'
import Api from '../../config/Api';
import Parser from 'html-react-parser';

const Index = () => {

    const [noticeList,setNoticeList] = useState();

    useEffect( ()=> {

        try{

            Api.post('home/notification_list/').then( res => {

                const response = res.data;
                if(response.status)
                    setNoticeList(response.oData.notice);

            });

        }catch(error){

            console.log(error);

        }

    },[]);
    
    console.log(noticeList);

    return (
        <div className='card'>
            <div className='card-header'>
                <h5 className='text-white'>Notice</h5>
            </div>
            <div className="card-body">
                <marquee behavior="scroll" scrollamount="1" direction="up">
                    <ul className='notification-list'>
                        {
                            noticeList?noticeList.map( (notice) =>{
                                {return(<li key={notice.id}>{Parser(notice.highlighted_content)}</li>)}
                            }):''
                        }
                        
                    </ul>
                </marquee>
            </div>
        </div>
    )
}

export default Index