import React,{useState,useEffect} from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import CourseSingle from '../../components/Courses/CourseSingle';

// Courses Image
import cseBanner from '../../assets/image/CSEbanner.jpg';
import eceBanner from '../../assets/image/ECEBanner.jpg';
import ITbanner  from '../../assets/image/ITbanner.jpg';
import BannerMECH from '../../assets/image/BannerMECH.jpg';

import Api from "../../config/Api";

const Courses = () => { 

    const[departmentList,setDepartmentList] = useState([]);

    useEffect( ()=> {

        Api.post('home/get_department').then( (res) => {
            
            const response = res.data;

            if(response.status == 1) {

                setDepartmentList(response.oData.department_list);

            }
        }).catch(error => {
            console.log(error);
        })

    },[])

    console.log(departmentList);

    return (
        <div className="rs-popular-courses main-home event-bg pt-100 pb-100 md-pt-70 md-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title3 text-center mb-44"
                    subtitleClass="sub-title"
                    subtitle="Courses"
                    titleClass="title black-color"
                    title="WE HAVE FAITH IN OUR STUDENT FUTURE"
                />
                <div className="row">
                    {
                        departmentList.map( (department,key) => {

                            return(

                                <div className="col-lg-3 col-md-6 mb-30">
                                    <CourseSingle
                                        itemClass="courses-item"
                                        image= {department.departmentPic}
                                        title={department.departmentName}
                                        pricing="$40.00"
                                        studentQuantity={department.departmentStudent}
                                        lessonsQuantity={department.departmentSemester}
                                        courseLink={'department/'+department.departmentUID}
                                    />
                                </div>

                            )

                        })
                    }
                   

                    {/* <div className="col-lg-3 col-md-6 mb-30">
                        <CourseSingle
                            itemClass="courses-item"
                            image={BannerMECH}
                            title="Mechanical Engineering"
                            pricing="$35.00"
                            studentQuantity="21"
                            lessonsQuantity="7"
                            courseLink="#"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 md-mb-30">
                        <CourseSingle
                            itemClass="courses-item"
                            image={eceBanner}
                            title="Electronics & Communications"
                            pricing="$32.00"
                            studentQuantity="45"
                            lessonsQuantity="12"
                            courseLink="#"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CourseSingle
                            itemClass="courses-item"
                            image={cseBanner}
                            title="Computer Science & Engineering"
                            // pricing="Free"
                            studentQuantity="25"
                            lessonsQuantity="6"
                            courseLink="#"
                        />
                    </div> */}
                    
                </div>
            </div>
        </div>
    );
}

export default Courses;