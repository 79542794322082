import React from 'react'
import SectionTitle from '../../components/Common/SectionTitle';
import AboutImage from '../../assets/img/about-us/about-us-1.jpg'
import Mukul from '../../assets/img/vice-chancellor/mukul.jpg'

const TextData = () => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-lg-8 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="Message from Vice- chancellor"
                                titleClass="title mb-16"
                                title=""
                                descClass="mb-22" //bold-text
                                description="The university college of Engineering and Technology (UCET) is the only engineering college in Jharkhand which is directly managed and maintained by the Vinoba Bhave University, a pioneer leading University of Jharkhand. UCET has come up as an abode of scientific learning and technical excellence and developing human resource with technical skill to serve the various stakeholders. The prime motive of UCET is to foster in our students a passion for challenge and achievement, a thirst for knowledge and a desire to make their career with company that embraces collaboration. Our students has been commended by the visiting teams for their strong team spirit, discipline, leadership qualities and technical expertise. I am sure that our student will prove to be an asset for the organization in which they are provided the opportunity. I wish all success in this venture."
                                secondDescClass="desc"
                                secondDescription=""
                                signature = "Thanks & Regards, <br/>(Dr.Mukul Narayan Deo) <br/> Vice- chancellor <br/> Vinoba Bhave University <br/> Hazaribag-825301"
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                    <div className="col-lg-3 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={Mukul} alt="About Image" />
                        </div>
                        <div style={ {textAlign : 'center', padding:'10px',fontSize:'16px',fontWeight:500} }>
                            Dr. Mukul Narayn Deo
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500,color:'#6c8391'} }>
                            E-Mail :vc@vbu.ac.in <br/>
                            Mob: 8987791005
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TextData