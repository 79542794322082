import React from 'react'

import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Newsletter from '../../components/Common/Newsletter';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CyberMain from './CyberMain';

// Image
import Logo from '../../assets/img/logo/Logo1.png';
import footerLogo from '../../assets/img/logo/Logo1.png';

import bannerbg from '../../assets/img/cyber/cyber-bg.png';
import FeatureStyle1 from '../../components/Feature/FeatureStyle1';

const Cyber = () => {

  return (
    <React.Fragment>
        <OffWrap />
        <Header
          parentMenu='cyber'
          headerNormalLogo={Logo}
          headerStickyLogo={Logo}
          CanvasLogo={Logo}
          mobileNormalLogo={Logo}
          CanvasClass="right_menu_togle hidden-md"
          headerClass="full-width-header header-style1 home8-style4"
          TopBar='enable'
          TopBarClass="topbar-area home8-topbar"
          emailAddress='info@ucet.ac.in'
          Location='VINOBA BHAVE UNIVERSITY, HAZARIBAG'
        />

        {/* breadcrumb-area-start */}
        
        <SiteBreadcrumb
          pageTitle="Cyber Defense"
          pageName="cyber defense"
          breadcrumbsImg={bannerbg}
        />
        {/* <div className="rs-slider main-home">
          <FeatureStyle1/>
        </div> */}
        
        
        <CyberMain/>


        <Newsletter
            sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
            titleClass="title mb-0 white-color"
        />

        <Footer
            footerClass="rs-footer home9-style main-home"
            footerLogo={footerLogo}
        />
        <SearchModal />
    </React.Fragment>
  );
}

export default Cyber