import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import TeamDetailsProgressBar from '../../components/Elements/Progress/TeamDetailsProgressBar';
// Breadcrumbs Bg
import teamImg1 from '../../assets/img/team/9.jpg';
import { useParams } from 'react-router-dom';
import Api from '../../config/Api';

const TeamSingleMain = () => {

    const {id} = useParams();
    const[staffDetails,setStaffDetails] = useState('');

    useEffect( () => {

        const formData = new FormData();
        formData.append('staff_uid',id);

        Api.post('home/get_staff_details',formData).then( res => {
             
            const response = res.data;

            if(response.status == 1 ){

                setStaffDetails(response.oData.staff_details);

            }

        },[]);

    },[id]);


    if(!staffDetails)
        return(

            <div className='profile-section pt-100 pb-90 md-pt-80 md-pb-70'>
                <div className="container">
                    <div className="row clearfix">
                        <div className='text-center'>
                            Staff details not found...
                        </div>
                    </div>
                </div>
            </div>

        )

    return (
        <div className="profile-section pt-100 pb-90 md-pt-80 md-pb-70">
            <div className="container">
                <div className="row clearfix">
                    <div className="image-column col-lg-5 md-mb-50">
                        <div className="inner-column mb-50 md-mb-0">
                            <div className="image">
                                <img src={staffDetails.staffPic} alt="images" />
                            </div>
                            <div className="team-content text-center">
                                <h3>{staffDetails.staffName}</h3>
                                <div className="text">{staffDetails.departmentName}</div>
                                <ul className="personal-info">
                                    <li className="email">
                                        <span><i className="glyph-icon flaticon-email"> </i> </span>
                                        <Link to="#">{staffDetails.staffEmail}</Link>
                                    </li>
                                    <li className="phone">
                                        <span><i className="glyph-icon flaticon-call"></i></span>
                                        <Link to="#">{staffDetails.staffMobile}</Link>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="social-box">
                                <Link to="#">
                                    <i className="fa fa-facebook-square"></i>
                                </Link>
                                <Link to="#">
                                    <i className="fa fa-twitter-square"></i>
                                </Link>
                                <Link to="#">
                                    <i className="fa fa-linkedin-square"></i>
                                </Link>
                                <Link to="#">
                                    <i className="fa fa-github"></i>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="content-column col-lg-7 pl-60 pt-50 md-pl-14 md-pt-0">
                        <div className="inner-column">
                            {/* <h2>{staffDetails.staffName}</h2> */}
                            {/* <h4>A certified instructor From Educavo</h4> */}
                            {/* <ul className="student-list">
                                <li>23,564 Total Students</li>
                                <li><span className="theme_color">4.5</span> <span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span> (1254 Rating)</li>
                                <li>256 Reviews</li>
                            </ul> */}
                            <h5>About Me</h5>
                            <p>
                                {staffDetails.staffAbout}
                            </p>
                            <div className="team-skill mb-50">
                                {/* <h3 className="skill-title">Our Teacher Skill:</h3> */}
                                <div className="row">
                                    <div className="col-md-4">
                                        Date of Joining : {staffDetails.staffJoinedOn}
                                    </div>
                                    <div className="col-md-8">
                                        Qualification(s) : {staffDetails.staffQualification}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-12">
                        <div className="content-part">
                            <h3 className="title">25 That Prevent Job Seekers From Overcoming Failure</h3>
                            <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo. In libero urna, venenatis sit amet ornare non, suscipit nec risus. Sed consequat justo non mauris pretium at tempor justo sodales. Quisque tincidunt laoreet malesuada. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur.I was skeptical of SEO and content marketing at first, but the Lorem Ipsum Company not only proved itself financially speaking, but the response I have received from customers is incredible.
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default TeamSingleMain;