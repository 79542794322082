import React from 'react'
import SectionTitle from '../../components/Common/SectionTitle';
import AboutImage from '../../assets/img/about-us/about-us-1.jpg'
import Mukul from '../../assets/img/vice-chancellor/mukul.jpg'

const TextData = () => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-lg-10 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="Message from Bursar-I"
                                titleClass="title mb-16"
                                title=""
                                descClass="mb-22" //bold-text
                                description='"Self-control, fearlessness and independence of thinking, these are three tests of education. Only that country is educated where these three qualities find expression." I welcome you on the World-Wide-Web and hope that your association with the UCET will be enjoyable and beneficial. Use the information on this web site to find facilities, services and information that will help you with your career choices. UCET is in an ideal position to offer its students an enriching experience that is unrepeatable elsewhere. Here at UCET, students will be able to not only benefit from a state-of-the-art research and academic facilities, but celebrate the explosion of natural and cultural diversity of the Hazaribag. Knowledge liberates the mind and only a society that is able to devise mediums, methods and strategies to propagate knowledge and educate its every single member can hope to provide its present and future generations an environment of growth, dignity, respect and constructive development. Let UCET be the most sought after destination for all aspirants who want to achieve academic excellence and want to be good citizens. Best wishes on a successful academic career at UCET.'
                                secondDescClass="desc"
                                secondDescription=""
                                signature = "Thanks & Regards, <br/>(Dr. K. K. Gupta) <br/> Bursar-I <br/> Vinoba Bhave University <br/> Hazaribag-825301"
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                    <div className="col-lg-2 mt--24 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        {/* <div className="img-part"> */}
                            {/* <img className="about-main" src={Mukul} alt="About Image" /> */}
                        {/* </div> */}
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500} }>
                            Dr. A. K. Saha
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500,color:'#6c8391'} }>
                            E-Mail : drkrishna@vbu.ac.in<br/>
                            Mob: Nil
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TextData