import React from 'react'

const Hod = (props) => {

    const hod = props.hod; 
    if(hod){

        return (
            <>
            {/* // <div className="col-lg-3  order-last padding-0 md-pl-14 md-pr-14 md-mb-30"> */}
                {
                    (hod.staffPic)?(
                        <img src={hod.staffPic} alt="hod"></img>
                    ):''
                }
                <h5 style={{paddingTop:'10px'}}>{hod.staffName}</h5>
                <p style={{marginTop:'-20px'}}>HEAD OF THE DEPARTMENT</p>
                <p style={{color:'#6c8391'}}>Gender  : {hod.staffGender} </p>
                <p style={{marginTop:'-20px',color:'#6c8391'}}>Date of Joining : {hod.staffJoinedOn}</p>
                <p style={{marginTop:'-20px',color:'#6c8391'}}>Qualification(s): {hod.staffQualification}</p>
                
                {/* {
                    (course)?(
                        <CourseStructure course = {course} />
                    ):''
                } */}
            {/* // </div> */}
            </>
        )

    }else{

        return (
            <div className="col-lg-3 mt--24 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">No data</div>
        )
    }
}

export default Hod