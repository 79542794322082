import React,{useEffect,useState} from 'react'
import Parser from 'html-react-parser';

const LeftSIde = ( props ) => {

    const department = props.departmentData;
    const [description,setDescription] = useState();
    

    useEffect( () => {

        if(department){
            setDescription(department.departmentDescription);
        }

    },[department])

    // console.log(departmentDescription);

    if(description){
        return(
            <>
                <div className="col-lg-9 pr-70 md-pr-14">
                    <section className='sec-title mb-44 md-mb-0'>
                        {/* <div className='sub-title orange'>{(department)?department.departmentName:''}</div> */}
                        <p className='mb-22'> 
                            {Parser(description)}
                        </p>
                        
                    </section>
                </div>

                
            </>
            
            
        )
    }else{

        return(
            <div className="col-lg-9 pr-70 md-pr-14">
                <section className='sec-title mb-44 md-mb-0'>
                    Department not found
                </section>
            </div>
        )
    }
   
}

export default LeftSIde