import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'

import CourseSingleTwo from '../../components/Courses/CourseSingleTwo';
import Api from '../../config/Api';

const List = () => {

    const[{page},setPage] = useState({
                                page : 1
                            });
    const[limit,setLimit] = useState(10);
    const[department,setDepartment] = useState([]);
    const[{staffList},setStaffList]  = useState({
                                            staffList : [],
                                        });
    const[departmentId,setDepartmentId] = useState(0);
    const[offSet,setOffSet] = useState(0);
    const[totalStaff,setTotalStaff] = useState();
    const [{totalPages},setTotalPages] = useState({
                                            totalPages : 0
                                        });

    useEffect( ()=>{

        try{
            
            const formData = new FormData();

            formData.append('page',page);
            formData.append('limit',limit)
            formData.append('staff_id','');
            formData.append('staff_dept_id',departmentId);

            Api.post('home/faculty_list',formData).then( res=> {
                
                const response = res.data;

                if(response.status == 1) {

                    setDepartment(response.oData.department_list);
                    setStaffList({staffList:response.oData.staff_list});
                    setOffSet(response.oData.offset);
                    setTotalStaff(response.oData.staff_count);
                    setTotalPages({
                                    totalPages:response.oData.total_page
                                });
                }
                console.log(response);

            });

        }catch(error){

            console.log(error);
        }

    },[page,departmentId]);


    const pageNumbers = [];
    if(totalPages > 1 ){

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    }

    const listClassAdd = () => {
        document.getElementById("rs-popular-course").classList.add('list-view');
    };

    const listClassRemove = () => {
        document.getElementById("rs-popular-course").classList.remove('list-view');
    };

    const updateDepartmentId = (depId) => {

        setDepartmentId(depId);
        setPage({
            page : 1
        });
    }

    return (
        <div id="rs-popular-course" className="rs-popular-courses style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row">
                
                    <div className="col-lg-11 pr-50 md-pr-14">
                        <div className="course-search-part">
                            <div className="course-view-part">
                                <div className="view-icons">
                                    <button onClick={listClassRemove} className="view-grid mr-10"><i className="fa fa-th-large"></i></button>
                                    <button onClick={listClassAdd} className="view-list"><i className="fa fa-list-ul"></i></button>
                                </div>
                                <div className="view-text">Showing {Number(offSet) } - {Number(offSet) + Number(staffList.length) - 1 }  of {totalStaff} results</div>
                            </div>
                            <div className="type-form">
                                <form method="post" action="#">
                                    <div className="form-group mb-0">
                                        <div className="custom-select-box" onChange={(e)=> { updateDepartmentId(e.target.value)}}>
                                            <select id="timing">
                                                <option value="">Choose depatment</option>
                                                {
                                                    department.map( (d,key) => {
                                                        
                                                        return(
                                                            <option value={d.PKDepartmentID} key ={key}>{d.departmentName}</option>
                                                        )

                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="course-part clearfix">
                            {

                                staffList.map( (staff,key) => {

                                    return(
                                        <CourseSingleTwo
                                            courseClass="courses-item"
                                            courseImg={staff.staffPic}
                                            courseTitle={staff.staffName}
                                            courseCategory = {staff.departmentName}
                                            qualification  = {staff.staffQualification}
                                            mailId         = {staff.staffEmail}
                                            userPhone      = {staff.staffMobile}
                                            catLink        = {"/faculty/"+staff.staffUID}
                                            departmentUID  = {staff.departmentUID}
                                             
                                        />
                                    )

                                })
                            }
                            
                            
                            
                        </div>

                        {
                            (pageNumbers.length > 0 )? (

                                <div className="pagination-area orange-color text-center mt-30 md-mt-0">
                                    <ul className="pagination-part">
                                        {
                                            (page !=1)?(
                                                <li>
                                                    <Link to="#"
                                                        onClick={ ()=> {

                                                            setPage({
                                                                page : Number(page-1)
                                                            });
                                                        
                                                        }}
                                                    > 
                                                        <i className="fa fa-long-arrow-left"></i> Previous 
                                                    </Link>
                                                </li>
                                            ):''
                                        }

                                        {
                                            pageNumbers.map( (i,key) => {
                                                return(
                                                    <li className={(page == i )?'active':''} key={key}>
                                                        <Link to="#"
                                                            onClick={ ()=> {
                                                                setPage({
                                                                    page : key + 1 
                                                                });
                                                            
                                                            }}
                                                        >{i}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                        
                                        {
                                            (page !=totalPages)?(
                                                <li>
                                                    <Link to="#"
                                                        onClick={ ()=> {

                                                            setPage({
                                                                page : Number(page+1)
                                                            });
                                                        
                                                        }}
                                                    >
                                                        Next <i className="fa fa-long-arrow-right"></i>
                                                    </Link>
                                                </li>
                                            ):''
                                        }
                                        
                                    </ul>
                                </div>
                            ):''
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default List