import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';

const Cta = () => {

    return (
        <div className="rs-cta main-home"> 
            <div className="partition-bg-wrap">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-6 col-lg-6 pl-70 md-pl-14">
                            <SectionTitle
                                sectionClass="sec-title3 mb-40"
                                titleClass="title white-color mb-16"
                                title="ABOUT THE UNIVERSITY"
                                descClass="desc white-color pr-100 md-pr-0"
                                description="The Vinoba Bhave University was established in the year 1992 with it's headquarter at Hazaribag, the Divisional Headquarter of North Chotanagpur Division of the state of JHARKHAND.

                                It is situated at a distance of 100 K.M. from Ranchi, the capital of Jharkhand State. Vinoba Bhave University is imparting postgraduate teaching and research in Physical Science, Life Science, Earth Science, Social Science, Humanities, Commerce, Technology and Medical Science. Beside, 22 colleges are managed and maintained by the university, including St. Columba’s College, Hazaribag one of the oldest and well established colleges in Eastern India (Estd. 1899)."
                            />
                            <div className="btn-part">
                                <Link className="readon orange-btn transparent" to="/about-us">Read more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cta;