import React,{useState,useEffect} from 'react'

import icon1 from '../../assets/img/categories/icons/1.png';
import SectionTitle from '../../components/Common/SectionTitle';
import CategoriesSingleTwo from '../notice/CategoriesSingleTwo';

import Notification from '../../components/notification';
import Api from '../../config/Api';
import {  useLocation } from 'react-router-dom';


const TextData = () => {

    
    
    return (
        
        <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
            <div className="container">
                <div className="row y-middle mb-50 md-mb-30">
                    <div className="col-md-6 sm-mb-30">
                        <SectionTitle
                            sectionClass="sec-title"
                            subtitleClass="sub-title primary"
                            subtitle="Placement notices"
                            titleClass="title mb-0"
                            // title="Our Top Categories"
                        />
                    </div>
                    <div className="col-md-6">
                        {/* <div className="btn-part text-right sm-text-left">
                            <Link to="course" className="readon">View All Categories</Link>
                        </div> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <Notification/>
                    </div>
                    <div className="col-md-8">

                        <CategoriesSingleTwo
                                        categoriesClass="categories-item"
                                        title="Test Tender11"
                                        quantity=""
                                        iconImg={icon1}
                                        courseQuantity = "tender contents - test content <br> 2019-03-13 "
                                        date   = '2022-10-18'
                                        catLink = "http://ucetvbu.ac.in/assets/uploads/tender/files/tenderFile000001.pdf"
                        />
                        
                    </div>

                </div>
            
            </div>
        </div>
    )
}

export default TextData