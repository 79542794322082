
import { Link } from 'react-router-dom';

const SinglePost = (props) => {
    const { blogClass, blogImage, blogTitle, contentClass, blogDesc, blogCategory, blogAuthor, blogPublishedDate, blogButtonClass, blogButtonText } = props;

    var length = 35;
    var trimmedBlogDesc = blogDesc.length > length ? 
                        blogDesc.substring(0, length - 3) + "..." : 
                        blogDesc;

    return (
        <div className={blogClass ? blogClass : 'blog-item mb-44'}>
            <div className="image-part">
                <Link to="#">
                    {
                        (blogImage)&& <img
                                        src={blogImage}
                                        alt={blogTitle}
                                    />
                    }
                    
                </Link>
            </div>
            <div className={contentClass ? contentClass : 'blog-content'}>
                <ul className="blog-meta">
                    <li className="date"><i className="fa fa-calendar-check-o"></i> {blogPublishedDate ? blogPublishedDate : ' January 21, 2021'}</li>
                    <li className="admin"><i className="fa fa-user-o"></i> {blogAuthor ? blogAuthor : 'admin'}</li>
                </ul>
                <h3 className="title">
                    <Link to="#">
                        {blogTitle ? blogTitle : 'Open Source Job Report Show More Openings Fewer '}
                    </Link>
                </h3>
                <div className="desc">{trimmedBlogDesc ? trimmedBlogDesc : ''}</div>
                <div className="btn-btm">
                    <div className="cat-list">
                        {/* <ul className="post-categories">
                            <li>
                                <Link to="/blog">
                                    {blogCategory ? blogCategory : ''}
                                </Link>
                            </li>
                        </ul> */}
                    </div>
                    <div className={blogButtonClass ? blogButtonClass : 'rs-view-btn'}>
                        <Link to="#">
                            {blogButtonText ? blogButtonText : 'Read More'}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SinglePost