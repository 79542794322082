import React from 'react'
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import CourseSingleThree from '../../components/Courses/CourseSingleThree';

import image1 from '../../assets/img/courses/style2/1.png';
import image2 from '../../assets/img/courses/style2/2.png';
import image3 from '../../assets/img/courses/style2/3.png';
import image4 from '../../assets/img/courses/style2/4.png';
import image5 from '../../assets/img/courses/style2/5.png';
import image6 from '../../assets/img/courses/style2/6.png';

const CyberFeatures = () => {

    return (
        <div className="rs-popular-courses style2 white-bg pt-94 pb-100 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <CourseSingleThree
                            courseClass="course-wrap"
                            courseCategory="Online Classroom Session"
                            courseImg={image2}
                            courseTitle="840 hours of Online Live Sessions"
                            // coursePrice="$23"
                            // studentQuantity="25"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <CourseSingleThree
                            courseClass="course-wrap"
                            courseCategory="SIMULATOR BASED EXPERIENTIAL LEARNING"
                            courseImg={image3}
                            courseTitle="200 hours of Simulation & Scenario Based Learning "
                            coursePrice="$28"
                            studentQuantity="25"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <CourseSingleThree
                            courseClass="course-wrap"
                            courseCategory="5E LEARNING MODEL"
                            courseImg={image4}
                            courseTitle="Continuous Evaluation Process"
                            // coursePrice="$15"
                            // studentQuantity="14"
                        />
                    </div>

                    <div className="col-lg-12 pr-70 md-pr-14">
                        <div className="about-content">
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="5E Model"
                                titleClass="title mb-16"
                                title=""
                                descClass="bold-text mb-22"
                                description=""
                                secondDescClass="desc"
                                secondDescription="We have aligned our teaching process with 5E Model of Learning. The 5E Model, developed in 1987 by the Biological 
                                Sciences Curriculum Study (USA), promotes collaborative, active learning in which students work together to solve problems
                                and investigate new concepts by asking questions, observing, analyzing, and drawing conclusions. Our Training 
                                methodology integrate intrinsically with the 5E learning Model for navigating students to build their own understanding 
                                from experiences and new ideas. We have advanced our training methodology with continuous evaluation & research - 
                                based pedagogy approach and embedded Problem-based Learning, Scenario-based Learning and Universal Design for 
                                Learning. The model gets further integrated with our Learning Management System and Data Science based evaluation
                                system to improve learning cycle of every student in an inclusive way."

                            />
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    );
}

export default CyberFeatures