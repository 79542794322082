import React from 'react'
import SectionTitle from '../../components/Common/SectionTitle';
import profile  from '../../assets/img/nss-officer/nss-officer.jpg'

const TextData = () => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-lg-10 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="Message from NSS Program Officer"
                                titleClass="title mb-16"
                                title=""
                                descClass="mb-22" //bold-text
                                description="National Service Scheme (N.S.S.) is an active unit of UCET, VBU. The Motto of the N.S.S. programme is “Education through service” and “Not me but you”. The volunteers of N.S.S. are actively involved in various nation building activities. N.S.S. Unit of UCET started in july 2013 and the following activities have been done. Visit to “Old Age Home” Hazaribag in September’2013. “Raksha Bandhan movement of trees” conducted on 7th october 2013 near tatl jhariya forest and 28th october, Near Barkagaon (at patra forest). Sports activities conducted on the occasion of swami vivekanand 150th birthday. Plantation of trees and other environmental activities conducted from time to time in UCET, VBU campus. Assisted government in conducting parliamentary election 2014."
                                secondDescClass="desc"
                                secondDescription=""
                                signature = "Thanks & Regards,<br/>(Dr. Khemlal Mahto)<br/>NSS Program Officer<br/>Vinoba Bhave University<br/>Hazaribag-825301"
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                    <div className="col-lg-2 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={profile} alt="About Image" />
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500} }>
                            Dr. Khemlal Mahto
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500,color:'#6c8391'} }>
                            {/* E-Mail :pvc@vbu.ac.in <br/>
                            Mob: 9470303568 */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TextData