import React,{useEffect,useState} from 'react';
import SliderDefault from '../../components/Slider/SliderDefault';
import Categories from './CategoriesSection';
import Courses from './CoursesSection';
import Cta from './CtaSection'; 
import FaqSection from './FaqSection';
import Api from '../../config/Api';
// import Testimonial from './TestimonialSection';
import Blog from './BlogSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import ScrollNews from '../../components/ScrollNews';
const HomeMain = () => {
	
	const [{sliderImage,notice,events}, setHomeData] = useState({
        sliderImage : null,
        notice : null,
		events : []
    });
    
    useEffect( ()=> {

        try{
            
            Api.post('home/home_data').then( res=> {

                const data = res.data;
                const {slider_images,notice,events} = data.oData;

                setHomeData({
                    sliderImage : slider_images,
                    notice      : notice,
					events      : events
                });
                
            });

        }catch(error){
            console.log(error);
        }

    },[]);

	return (
		<React.Fragment>
			{/* SliderDefault-start */}
			<SliderDefault  images = {sliderImage} />
			<ScrollNews/>
			{/* <Notice/> */}
			{/* SliderDefault-start */}
			<FaqSection noticeData = {notice} />
			{/* Categories-area-start */}
			{/* <Categories /> */}
			{/* Categories-area-end */}

			{/* Cta-area-start */}
			<Cta />
			{/* Cta-area-end */}

			{/* Courses-area-start */}
			<Courses />
			{/* Courses-area-end */}

			{/* FaqSection-area-start */}
				
			{/* FaqSection-area-end */}

			{/* testmonial-area-start */}
			{/* <Testimonial /> */}
			{/* testmonial-area-end */}

			{/* blog-area-start */}
			{
				(events.length > 0 )&&<Blog events ={events}/>
			}
			
			{/* blog-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup orange-color"
			/>
			{/* scrolltop-end */}

		</React.Fragment>
  	)
}

export default HomeMain