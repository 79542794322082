import React from 'react'
import SectionTitle from '../../components/Common/SectionTitle';
import CategoriesSingleTwo from '../notice/CategoriesSingleTwo';

import icon1 from '../../assets/img/categories/icons/1.png';


const TextData = () => {
    return (
        <>
             <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
                <div className="container">
                    <div className="row y-middle mb-50 md-mb-30">
                        <div className="col-md-6 sm-mb-30">
                            <SectionTitle
                                sectionClass="sec-title"
                                subtitleClass="sub-title primary"
                                subtitle="Exam Notification Board"
                                titleClass="title mb-0"
                                // title="Our Top Categories"
                            />
                        </div>
                        <div className="col-md-6">
                            {/* <div className="btn-part text-right sm-text-left">
                                <Link to="course" className="readon">View All Categories</Link>
                            </div> */}
                        </div>
                    </div>
                    <div className='row'>
                        <CategoriesSingleTwo
                                    categoriesClass="categories-item"
                                    title='1st sem asdasd'
                                    quantity="notice for admission"
                                    iconImg={icon1}
                                    courseQuantity = "Computer Science & Engineering <br/><br/> Posted On: Posted On: 2019-02-26 <br/>"
                                    date   = '2022-10-18'
                                    catLink = "http://ucetvbu.ac.in/assets/uploads/exam/notification/ExamResultImage000008.jpg"
                        />

                        <CategoriesSingleTwo
                                    categoriesClass="categories-item"
                                    title='1st sem'
                                    quantity="notice for admission"
                                    iconImg={icon1}
                                    courseQuantity = "Computer Science & Engineering <br/><br/> Posted On: Posted On:  2019-02-22 <br/>"
                                    date   = '2022-10-18'
                                    catLink = "http://ucetvbu.ac.in/assets/uploads/exam/notification/ExamResultImage000007.pdf"
                        />



                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default TextData