import React, { useEffect,useState } from 'react';
import ModalVideo from 'react-modal-video';
import Parser from 'html-react-parser';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const FaqSection = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-faq-part style1 orange-style pt-100 pb-100 md-pt-70 md-pb-70">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='YLN1Argi7ik' onClose={() => { openModal(); }} />
            <div className="container">
                <div className="row">

                    <div className="col-lg-8 padding-0">
                        <div className="img-part media-icon orange-color">
                            {/* <a href="#" className="popup-videos" onClick={() => { openModal(); }}><i className="fa fa-play"></i></a> */}
                        </div>
                    </div>
                    {/* <div className='col-lg-1'></div> */}
                    <div className="col-lg-4">
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='text-white'>Notice</h5>
                            </div>
                            <div className="card-body">
                                <marquee behavior="scroll" scrollamount="1" direction="up">
                                    <ul className='notification-list'>
                                        {
                                            props.noticeData?props.noticeData.map( (notice) =>{
                                                {return(<li key={notice.id}>
                                                    {
                                                        (notice.noticeFiles)?(
                                                            <a href={notice.noticeFiles} target="_blank">
                                                                {Parser(notice.highlighted_content)}
                                                            </a>
                                                        ):(
                                                            Parser(notice.highlighted_content)
                                                        )
                                                    }
                                                            
                                                        </li>)}
                                            }):''
                                        }
                                
                                    </ul>
                                </marquee>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 padding-0">
                        <div className="main-part">
                            {/* <div className="title mb-40 md-mb-14">
                                <h2 className="text-part">MESSAGE FROM DIRECTOR OF UCET</h2>
                            </div> */}
                            <div className="faq-content">
                                <Accordion className="accordion-style1" preExpanded={'a'}>
                                    <AccordionItem className="accordion-item" uuid="a">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="card-link">
                                                MESSAGE FROM DIRECTOR OF UCET
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            We are living in revolutionary times, a revolution brought on by dramatic advances in information Technology. If the steam engine, a new form of power and mechanization created an Industrial revolution over 150 year ago, computers and communications equipment have produced a technology revolution in the last half of the Twentieth century. Specifically speaking culture, business, economy, rules and regulations, customer preferences etc. have become so dynamic that we are in need of extra efforts all the time to cope up with them. In every sphere of life, we are finding competition and for achieving our goals earnest efforts in the right direction is inevitable. Therefore, we at University College of Engineering and Technology (UCET) are designing and maintaining the environment to accomplish the needful. We are emphasizing on providing quality technical education developing communication abilities, overall personality development of students and keeping up-to-date with the technology. To sum of, with our efforts, we are not teaching the students but we are making them learn all the techniques of learning which is actually required to sustain in the current environment. On the other end, we are promoting our students to participate in cultural and social activities. So that they could learn social responsibilities as well. Furthermore, we encourage our faculty members for higher studies and research work. This is them competent in the current scenario. In totally, we are moving ahead with our endeavour to achieve the organization goal to utilize the potential of every individual for their brighter future professional pursuit.
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/* <AccordionItem className="accordion-item" uuid="d">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="card-link">
                                                Notice
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        To get recognized as an Institute of Excellence in Engineering and Technology by imparting the desired knowledge and key skills to the students to serve for the service of nation building.
                                        </AccordionItemPanel>
                                    </AccordionItem> */}

                                    <AccordionItem className="accordion-item" uuid="b">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="card-link">
                                                Our Vision
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        To get recognized as an Institute of Excellence in Engineering and Technology by imparting the desired knowledge and key skills to the students to serve for the service of nation building.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    
                                    <AccordionItem className="accordion-item" uuid="c">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="card-link">
                                                Our Mission
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            1. To enhance creativity, development, innovation, and excellence through outcome- based education imparted in the most simple and precise way.
                                            2. To augment the interdisciplinary learning that ensures the skills of the student and enhances their problem-solving ability for the lifetime.
                                            3. To train the students to meet the challenging situations in the industries and rapidly changing world.
                                            4. To foster the students to become professional and maintain their ethical responsibility.
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                </Accordion>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default FaqSection;