import React from 'react'
import SectionTitle from '../../components/Common/SectionTitle';
import AboutImage from '../../assets/img/about-us/about-us-1.jpg'
import Mukul from '../../assets/img/vice-chancellor/mukul.jpg'

const TextData = () => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-lg-10 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="Message from Pro Vice Chancellor"
                                titleClass="title mb-16"
                                title=""
                                descClass="mb-22" //bold-text
                                description="The college was established in 2009 with a motto of delivering quality education to the students of Jharkhand is now ready with its talented pool of future change markers of the nation. The institutions imparting technical education to the youth of the state. Since inception, University College of Engineering & Technology (UCET) is dedicated to the objectives of creating highly trained manpower in various disciplines of Engineering & Technology. The institute is also dedicated to the contribution of higher scientific research in technology. The vision of the institute is to create an educational institution that will be admired globally and achieve the goal by continuous benchmarking and updated infrastructure and competent teaching faculty and to offer value-added education to the students as to inculcate in them the highest ethical standards and moral values of human life in the society. I wish all the very best to the UCET and hope it achieves greater height of success."
                                secondDescClass="desc"
                                secondDescription=""
                                signature = "Thanks & Regards, <br/>(Dr.Ajit Kumar Sinha) <br/> Pro Vice Chancellor <br/> Vinoba Bhave University <br/> Hazaribag-825301"
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                    <div className="col-lg-2 mt--24 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        {/* <div className="img-part"> */}
                            {/* <img className="about-main" src={Mukul} alt="About Image" /> */}
                        {/* </div> */}
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500} }>
                            Dr. Ajit Kumar Sinha
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500,color:'#6c8391'} }>
                            E-Mail :pvc@vbu.ac.in <br/>
                            Mob: 9470303568
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TextData