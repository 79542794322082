import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';

//Custom Components

import Home from '../pages/home';
import About from '../pages/about-2';
import ViceChancellor from '../pages/vice-chancellor';
import ProViceChancellor from '../pages/pro-vice-chancellor';
import Registrar from '../pages/registrar';
import Director from '../pages/director';
import Bursar1 from '../pages/bursar-1';
import Bursar2 from '../pages/bursar-2';
import InchargeProffessor from '../pages/incharge-proffessor';
import CE from '../pages/ce';
import NssOfficer from '../pages/nss-officer';
import Dec from '../pages/dec';
import Department from '../pages/Department';
import Activity from '../pages/activity/';
import Notice from '../pages/notice/';
import ExamNotification from '../pages/exam-notification/';
import Result from '../pages/results/';
import TP from '../pages/t&p/';
import PlacementNotices from '../pages/placement-notices/';
import Tender from '../pages/tenders/';
import Faculty from '../pages/Faculty/';
import TeamSingle from '../pages/team/team-single';


import CourseOne from '../pages/course';
import CourseTwo from '../pages/course-2';
import CourseThree from '../pages/course-3';
import CourseFour from '../pages/course-4';
import CourseFive from '../pages/course-5';
import CourseSix from '../pages/course-6';
import CourseSingle from '../pages/course/course-single';
import CourseCategoryPage from '../pages/course-categories';
import Team from '../pages/team';
import TeamTwo from '../pages/team-2';
import Event from '../pages/event';
import EventTwo from '../pages/event-2';
import EventThree from '../pages/event-3';
import Gallery from '../pages/gallery';
import GalleryTwo from '../pages/gallery-2';
import GalleryThree from '../pages/gallery-3';
import Shop from '../pages/shop';
import ShopSingle from '../pages/shop/shop-single';
import Cart from '../pages/shop/cart';
import Checkout from '../pages/shop/checkout';
import MyAccount from '../pages/shop/my-account';
import Faq from '../pages/faq';
import Login from '../pages/login';
import Register from '../pages/register';
import Blog from '../pages/blog';
import BlogLeft from '../pages/blog/BlogLeft';
import BlogRight from '../pages/blog/BlogRight';
import SinglePostLeftSidebar from '../pages/blog/single-post-left-sidebar';
import SinglePostRightSidebar from '../pages/blog/single-post-right-sidebar';
import SinglePostFullWidth from '../pages/blog/single-post-full-width';
import Contact from '../pages/contact';
import ContactTwo from '../pages/contact-2';
import ContactThree from '../pages/contact-3';
import ContactFour from '../pages/contact-4';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'
import Cyber from '../pages/cyber';
import Complaints from '../pages/complaints/';

const App = () => {
    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/about-us" component={About} />
                    <Route path="/vice-chancellor" component={ViceChancellor} />
                    <Route path="/pro-vice-chancellor" component={ProViceChancellor} />
                    <Route path="/registrar" component={Registrar} />
                    <Route path="/director" component={Director} />
                    <Route path="/bursar-1" component={Bursar1} />
                    <Route path="/bursar-2" component={Bursar2} />
                    <Route path="/incharge-proffessor" component={InchargeProffessor} />
                    <Route path="/ce" component={CE} />
                    <Route path="/nss-officer" component={NssOfficer} />
                    <Route path="/dec" component={Dec} />
                    <Route path='/department/:id' component={Department} />
                    <Route path='/complaints'component={Complaints} />
                    <Route path='/activity' component = {Activity} /> 
                    <Route path='/notice' component = {Notice} />
                    <Route path='/exam-notification' component = {ExamNotification} />
                    <Route path='/result' component = {Result} />
                    <Route path='/t&p/:id' component = {TP} />
                    <Route path='/placement-notices' component ={PlacementNotices} />
                    <Route path='/aicte' component ={PlacementNotices} />
                    <Route path='/teqip' component ={PlacementNotices} />
                    <Route path='/nba' component ={PlacementNotices} />
                    <Route path='/bog' component ={PlacementNotices} />
                    <Route path='/tenders' component ={Tender} />
                    <Route path='/gallery' component ={Gallery} />
                    <Route path='/facultys' component = {Faculty}  />
                    <Route path="/faculty/:id" component={TeamSingle} />
                    <Route path="/contact-us" component={Contact} />
                    <Route path="/cyber" component={Cyber} />
                    <Route path="/news/:id" component={SinglePostRightSidebar} />
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
