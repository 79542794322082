import React,{useEffect,useState} from 'react';
import Api from '../../config/Api';

import {useFormik} from 'formik';
import * as Yup from 'yup'

const ComplaintForm = (props) => {
    
    const { submitBtnClass, btnText } = props;
    const [departmentList,setDepartmentList] = useState([]);
    const [{loading,message,messageType},setState] = useState({
        loading : false,
        message : null,
        messageType    : false,
    });

    
    useEffect( () => {

        try{

            Api.post('Home/department_list').then( res => {

                const response = res.data; 
                setDepartmentList(response.oData.department_list);
                
            });


        }catch(error){

            console.log(error);
        }

    },[]);

    const formik =  useFormik( {

        initialValues : {

            name        : '',
            department  : '',
            message     : '',

        },

        validationSchema : Yup.object({

            name        : Yup.string().required('Name is requred'),
            department  : Yup.string().required('Department is requred'),
            message     : Yup.string().required('Message is requred'),

        }),

        onSubmit : values =>{

            const formData = new FormData();

            formData.append('name',values.name);
            formData.append('department',values.department);
            formData.append('message',values.message);

            try{

                Api.post('Home/complaints_register',formData).then( res => {

                    const response = res.data;

                    if(response.status == 1 ){

                        setState({
                            loading : false,
                            message : response.error,
                            messageType : true,
                         });

                    }

                });     

            }catch(error){

                console.log(error);

            }
        }

    });
    console.log(departmentList);
    return (
        <form id="contact-form" action="#" onSubmit={formik.handleSubmit} >
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="name" onChange={formik.handleChange} value={formik.values.name} placeholder="Name"  />
                    {
                        formik.touched && formik.errors.name?(
                            <label className='error'>{formik.errors.name}</label>
                        ):''
                    }
                </div>

                <div className="col-md-6 mb-30">
                    <select className='from-control' name='department' id='department' onChange={formik.handleChange} defaultValue={formik.values.department}>
                        <option value="">Choose department</option>
                        {
                            (departmentList)?(
                                
                                departmentList.map( (department) => {
                                    return(<option value={department.PKDepartmentID}>{department.departmentName}</option>)
                                })

                            ): ''
                        }

                    </select>
                    {
                        formik.touched.department && formik.errors.department ? (
                            <label className='error'>{formik.errors.department}</label>
                        ):''
                    }
                </div>

                {/* <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" name="phone" placeholder="Phone Number"  />
                </div> 

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="website" name="website" placeholder="Your Website"  />
                </div> */}

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" onChange={formik.handleChange} placeholder="Your message Here" defaultValue ={formik.values.message} ></textarea>
                    {
                        formik.touched.message && formik.errors.message?(
                            <label className='error'>{formik.errors.message}</label>
                        ):''
                    }
                </div>
                
                
                <div className="col-12 mb-30" id='response-message'>
                    <h5 className={(messageType)?'success':'error'}>{message}</h5>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="submit">{btnText ? btnText : 'Submit Now'}</button>
            </div>
        </form>
    );

}

export default ComplaintForm;