import React,{useState,useEffect} from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import FeatureStyle1 from '../Feature/FeatureStyle1';

const SliderDefault = ( props ) => {

    // const [slider,setSlider] = useState([]);

    // useEffect( () => {
    //     setSlider(props.images);
    // },[]); 

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed:1000,
        autoplay:true,
        autoplaySpeed:5000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div className="rs-slider main-home">
                <Slider {...sliderSettings}>
                    {
                        (props.images ) ? props.images.map( s => 

                            <div className="slider-content slide3" key = {s.id}>
                                <div style={
                                    {
                                        backgroundImage : "url("+s.image+")",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                    }
                                }>

                                    <div className="container">
                                        <div className="content-part">
                                            <div className="sl-sub-title">{(s.heading_1)?s.heading_1:''} </div>
                                            <h1 className="sl-title">{(s.heading_2)?s.heading_2:''} </h1>
                                            {
                                                (s.link)?<div className="sl-btn">
                                                                <Link className="readon orange-btn main-home" to={s.link}>Apply Now</Link>
                                                            </div>:''
                                            }
                                            
                                        </div>
                                    </div>

                                </div>
                            </div>

                        ):  <div className="slider-content slide3">
                                <div className="container">
                                    <div className="content-part">
                                        {/* <div className="sl-sub-title">Launching World's Unique </div> */}
                                        {/* <h1 className="sl-title">POST  GRADUATE DIPLOMA IN CYBER DEFENSE</h1> */}
                                        <div className="sl-btn">
                                            <Link className="readon orange-btn main-home" to="/cyber">Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    
                    
                </Slider>
                {/* <FeatureStyle1 /> */}
            </div>
        </React.Fragment>
    );
}

export default SliderDefault;