import React from 'react'
import SectionTitle from '../../components/Common/SectionTitle';
import profile  from '../../assets/img/ce/CE.jpg'

const TextData = () => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-lg-10 mt--24 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="No message from Mr. Arun Kumar Mishra"
                                titleClass="title mb-16"
                                title=""
                                descClass="mb-22" //bold-text
                                description=""
                                secondDescClass="desc"
                                secondDescription=""
                                signature = ""
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                    <div className="col-lg-2 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={profile} alt="About Image" />
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500} }>
                            Mr. Arun Kumar Mishra
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500,color:'#6c8391'} }>
                            {/* E-Mail :pvc@vbu.ac.in <br/>
                            Mob: 9470303568 */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TextData