import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Image
import aboutImg from '../../assets/img/about/history.png';
import tabImg1 from '../../assets/img/about/tab1.jpg';
import tabImg2 from '../../assets/img/about/tab2.jpg';
import tabImg3 from '../../assets/img/about/tab3.jpg';

const AboutTab = () => {

    let tab1 = "Admission",
        tab2 = "Fee Structure",
        tab3 = "Administration"
    const tabStyle = 'nav nav-tabs histort-part';

    return (
        <div className="rs-about style1 pt-100 pb-100 md-pt-70 md-pb-70">
            <div className="container">
                <Tabs>
                    <div className="row align-items-center">
                        <div className="col-lg-6 padding-0 md-pl-14 md-pr-14 md-mb-30 relative">
                            <div className="img-part">
                                <img 
                                    src={aboutImg}
                                    alt="About Image" 
                                />
                            </div>
                            <TabList className={tabStyle}>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-analysis "></i></span>{tab1}</button>
                                </Tab>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-banknote"></i></span>{tab2}</button>
                                </Tab>
                                {/* <Tab>
                                    <button><span className="icon-part"><i className="flaticon-analysis"></i></span>{tab3}</button>
                                </Tab> */}
                            </TabList>
                        </div>
                        <div className="col-lg-6 pl-120 md-pl-14">
                            <TabPanel>
                                <div className="about-history">
                                    <div className="sec-title mb-24">
                                        <h3 className="title">Admission Procedure and ELEGIBILITY</h3>
                                        <div className="desc">
                                            
                                            <h6>1. B. Tech ( Through JEE Main): First Semester/First Year</h6>
                                            Admission into First Semester/First Year of B.Tech. course through Jharkhand Combined Entrance Competitive Examination (JCECE) of Jharkhand Combined Entrance Competitive Examination Board (JCECEB) of Jharkhand Govt
                                            <br/>
                                            <h6>2. D to D ( Through D to DECE of JCECE Board) - Third Semester/Second Year</h6>
                                            Admission into 3rd Semester/2nd Year (Lateral Entry) of B.Tech. Courses through Diploma to Degree Entrance Competitive Examination (DtoDECE) of Jharkhand Combined Entrance Competitive Examination Board (JCECEB) of Jharkhand Govt.
                                        </div>
                                    </div>
                                    {/* <div className="tab-img">
                                        <img 
                                            src={tabImg1}
                                            alt="Tab Image" 
                                        />
                                    </div> */}
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="about-mission">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Fee Structure</h2>
                                        <div className="desc">
                                            <table className='table table-bordered'>
                                                
                                                <tr>
                                                    <td>01</td>
                                                    <td>SEMESTER FEE</td>
                                                    <td>Rs.30,000/-</td>
                                                </tr>

                                                <tr>
                                                    <td>02</td>
                                                    <td>ADMISSION FEE</td>
                                                    <td>Rs.5,000/-</td>
                                                </tr>

                                                <tr>
                                                    <td>03</td>
                                                    <td>CAUTION MONEY (REFUNDABLE)</td>
                                                    <td>Rs.5,000/-</td>
                                                </tr>

                                                <tr>
                                                    <td>04</td>
                                                    <td>PLACEMENT SERVICE</td>
                                                    <td>Rs.1,000/-</td>
                                                </tr>

                                                <tr>
                                                    <td>05</td>
                                                    <td>GENERAL MAINTENANCE</td>
                                                    <td>Rs.1,000/-</td>
                                                </tr>                                              

                                                <tr>
                                                    <td>06</td>
                                                    <td>I-CARD & MEDICAL FITNESS FEES	</td>
                                                    <td>Rs.2,000/-</td>
                                                </tr>

                                                <tr>
                                                    <td>Total</td>
                                                    <td>	</td>
                                                    <td> <span className='total-fee'> Rs.44,000/- </span></td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>
                                    {/* <div className="tab-img">
                                        <img 
                                            src={tabImg2}
                                            alt="Tab Image" 
                                        />
                                    </div> */}
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="about-admin">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Educavo Administration</h2>
                                        <div className="desc">At vero eos et accusamus et iusto odio dignissimos ducimus qui blan ditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, sim ilique sunt in culpa.</div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg3}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>
        </div>
    );
}

export default AboutTab;