import React from 'react'
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';

import TextData from './TextData';

import Logo from '../../assets/img/logo/dark-logo.png';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Newsletter from '../../components/Common/Newsletter';
import Footer from '../../components/Layout/Footer/Footer';
import footerLogo from '../../assets/img/logo/lite-logo.png';

import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

const index = () => {
  return (
    <React.Fragment>
        <OffWrap />
        <Header
            parentMenu='Complaints' 
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            CanvasLogo={Logo}
            mobileNormalLogo={Logo}
            CanvasClass="right_menu_togle hidden-md"
            headerClass="full-width-header header-style1 home8-style4"
            TopBar='enable'
            TopBarClass="topbar-area home8-topbar"
            emailAddress='support@website.com'
            Location='374 William S Canning Blvd, MA 2721, USA '
        />
        
        <SiteBreadcrumb
            pageTitle="Students Corner"
            pageName='Students Corner // Complaint Register'
            breadcrumbsImg={bannerbg}
        />
        <TextData/>

        <Newsletter
            sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
            titleClass="title mb-0 white-color"
        />

        <Footer
            footerClass="rs-footer home9-style main-home"
            footerLogo={footerLogo}
        />
        <SearchModal />
    </React.Fragment>
    
  )
}

export default index