import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import CategoriesSingleTwo from './CategoriesSingleTwo';

import icon1 from '../../assets/img/categories/icons/1.png';
import Api from '../../config/Api';
import { tr } from 'date-fns/locale';

const TextData = () => {

    const [{noticeList},setNoticeList] = useState({
        noticeList : []
    });

    useEffect( () => {

        try{

            Api.post('Home/notice_list').then( res => {
                
                const response = res.data;

                if(response.status == 1 ){

                    setNoticeList({
                        noticeList:response.oData.notice_list
                    })

                    console.log(response.oData.notice_list);
                }
                    

                

            });

        }catch(error){

            console.log(error);

        }

    },[]);

    console.log(noticeList.length);

    return (
        <>
            <div className="rs-categories gray-bg style1 pt-94 pb-70 md-pt-64 md-pb-40">
            <div className="container">
                <div className="row y-middle mb-50 md-mb-30">
                    <div className="col-md-6 sm-mb-30">
                        <SectionTitle
                            sectionClass="sec-title"
                            subtitleClass="sub-title primary"
                            subtitle="Notice Board"
                            titleClass="title mb-0"
                            // title="Our Top Categories"
                        />
                    </div>
                    <div className="col-md-6">
                        {/* <div className="btn-part text-right sm-text-left">
                            <Link to="course" className="readon">View All Categories</Link>
                        </div> */}
                    </div>
                </div>

                <div className="row">
                    {
                        noticeList.map( (notice) => {

                            return(
                                <CategoriesSingleTwo
                                    categoriesClass="categories-item"
                                    title={notice.noticeTitle}
                                    quantity="notice for admission"
                                    iconImg={icon1}
                                    courseQuantity = {notice.noticeContent}
                                    date   = '2022-10-18'
                                    catLink = {notice.noticeFiles}
                                />
                            );
                            
                        })
                    }

                </div>
            </div>
        </div>
        </>
    )
}

export default TextData