import React from 'react'
import { Cyber5E } from './Cyber5E'
import CyberFeatures from './CyberFeatures'
import CyberTab from './CyberTabSection'
import CyberTextSection from './CyberTextSection'

const CyberMain = () => {
  return (
    <React.Fragment>
      <CyberTextSection/>
      <CyberTab/>
      <CyberFeatures/>    
    </React.Fragment>
  )
}

export default CyberMain