import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu } = props;
    const location = useLocation();
    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ''}>
                <Link to="/">Home</Link>
            </li>           

            <li className={parentMenu === 'Facultys' ? 'current-menu-item' : ''}>
                <Link to="/facultys" className={location.pathname === "/facultys" ? "active-menu" : ""}>Facultys</Link>
            </li>

            <li className={parentMenu === 'Administration' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">Administration</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/vice-chancellor" className={location.pathname === "/vice-chancellor" ? "active-menu" : ""}>Vice-Chancellor</Link>
                    </li>
                    <li>
                        <Link to="/pro-vice-chancellor" className={location.pathname === "/pro-vice-chancellor" ? "active-menu" : ""}>Pro Vice-Chancellor</Link>
                    </li>
                    <li>
                        <Link to="/registrar" className={location.pathname === "/registrar" ? "active-menu" : ""}>Registrar</Link>
                    </li>
                    <li>
                        <Link to="/director" className={location.pathname === "/director" ? "active-menu" : ""}>Director Desk</Link>
                    </li>
                    <li>
                        <Link to="/bursar-1" className={location.pathname === "/bursar-1" ? "active-menu" : ""}>Bursar-I</Link>
                    </li>
                    <li>
                        <Link to="/bursar-2" className={location.pathname === "/bursar-2" ? "active-menu" : ""}>Bursar-II</Link>
                    </li>
                    <li>
                        <Link to="/incharge-proffessor" className={location.pathname === "/incharge-proffessor" ? "active-menu" : ""}>Prof-in-charge</Link>
                    </li>
                    <li>
                        <Link to="/ce" className={location.pathname === "/ce" ? "active-menu" : ""}>CE</Link>
                    </li>
                    <li>
                        <Link to="/nss-officer" className={location.pathname === "/nss-officer" ? "active-menu" : ""}>NSS Program Officer</Link>
                    </li>
                    <li>
                        <Link to="/dec" className={location.pathname === "/dec" ? "active-menu" : ""}>DCE</Link>
                    </li>
                </ul>
            </li>

            <li className={parentMenu === 'Department' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">Department</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/department/DPT5DP7434" className={location.pathname === "/department/DPT5DP7434" ? "active-menu" : ""}>Computer Science & Engineering</Link>
                    </li>
                    <li>
                        <Link to="/department/DPT5DP7545" className={location.pathname === "/department/DPT5DP7545" ? "active-menu" : ""}>Mechanical Engineering</Link>
                    </li>
                    <li>
                        <Link to="/department/DPT5DP3073" className={location.pathname === "/department/DPT5DP3073" ? "active-menu" : ""}>Mathematics</Link>
                    </li>
                    <li>
                        <Link to="/department/DPT3DP5862" className={location.pathname === "/department/DPT3DP5862" ? "active-menu" : ""}>Electronics & Communication Engineering</Link>
                    </li>
                    <li>
                        <Link to="/department/DPT4DP5526" className={location.pathname === "/department/DPT4DP5526" ? "active-menu" : ""}>Information Technology</Link>
                    </li>
                    <li>
                        <Link to="/department/DPT6DP1327" className={location.pathname === "/department/DPT6DP1327" ? "active-menu" : ""}>Chemistry</Link>
                    </li>
                    <li>
                        <Link to="/department/DPT9DP5296" className={location.pathname === "/department/DPT9DP5296" ? "active-menu" : ""}>Physics</Link>
                    </li>
                    <li>
                        <Link to="/department/DPT8DP7573" className={location.pathname === "/department/DPT8DP7573" ? "active-menu" : ""}>English</Link>
                    </li>
                </ul>
            </li>

            
            <li className={parentMenu === 'Complaints' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">Student Corner</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/complaints" className={location.pathname === "/complaints" ? "active-menu" : ""}>Complaints</Link>
                    </li>
                    {/* <li>
                        <Link to="/" className={location.pathname === "/contact-us" ? "active-menu" : ""}>Alumini</Link>
                    </li> */}
                    <li>
                        <Link to="/activity" className={location.pathname === "/activity" ? "active-menu" : ""}>Activity</Link>
                    </li>
                    <li>
                        <Link to="/notice" className={location.pathname === "/notice" ? "active-menu" : ""}>Notice</Link>
                    </li>
                    <li>
                        <Link to="/exam-notification" className={location.pathname === "/exam-notification" ? "active-menu" : ""}>Exam Notification</Link>
                    </li>
                    <li>
                        <Link to="/result" className={location.pathname === "/result" ? "active-menu" : ""}>Results</Link>
                    </li>
                    <li>
                        <a href='http://ucetvbu.ac.in/assets/website/design/frontend/images/t&p/List%20of%20Students%20who%20have%20qualify%20the%20GATE%20Exam.pdf' target="_blank">Achievement</a>
                        {/* <Link to="/" className={location.pathname === "/" ? "active-menu" : ""}>Achievement</Link> */}
                    </li>
                    <li>
                        <a href='http://ucetvbu.ac.in/assets/website/design/frontend/images/t&p/Doc2.pdf'  target="_blank" >Routine</a>
                        {/* <Link to="/" className={location.pathname === "/" ? "active-menu" : ""}>Routine</Link> */}
                    </li>
                    {/* <li>
                        <Link to="/" className={location.pathname === "/" ? "active-menu" : ""}>Mentor</Link>
                    </li> */}
                </ul>
            </li>

            <li className={parentMenu === 't&p' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">T&P Cell</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/t&p/director" className={location.pathname === "/t&p/director" ? "active-menu" : ""}>Director Message</Link>
                    </li>
                    
                    <li>
                        <Link to="/t&p/tp_message" className={location.pathname === "/t&p/tp_message" ? "active-menu" : ""}>T & P Cell Message</Link>
                    </li>
                    <li>
                        <a href="https://ucetvbu.ac.in/admin/uploads/tp/List%20of%20Members%20in%20Training.pdf" target="_blank" className={location.pathname === "/T & P Membe" ? "active-menu" : ""}>T & P Member</a>
                    </li>
                    <li>
                        <Link to="/placement-notices" className={location.pathname === "/placement-notices" ? "active-menu" : ""}>Placement Notices</Link>
                    </li>
                    <li>
                        <Link to="/t&p/placement_activity" className={location.pathname === "/t&p/placement_activity" ? "active-menu" : ""}>T & P Activity</Link>
                    </li>
                    <li>
                        <Link to="/t&p/placement_companies" className={location.pathname === "/t&p/placement_companies" ? "active-menu" : ""}>Placement companies</Link>
                    </li>
                    
                    {/* <li>
                        <Link to="/" className={location.pathname === "/" ? "active-menu" : ""}>Routine</Link>
                    </li> */}
                    {/* <li>
                        <Link to="/" className={location.pathname === "/" ? "active-menu" : ""}>Mentor</Link>
                    </li> */}
                </ul>
            </li>

            <li className={parentMenu === 'more' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">More</Link>
                <ul className="sub-menu">
                    <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                        <Link to="/about-us">About Us</Link> 
                    </li>      
                    <li>
                        <Link to="/tenders" className={location.pathname === "/tenders" ? "active-menu" : ""}>Tenders</Link>
                    </li>
                    
                    <li>
                        <a href="http://ucetvbu.ac.in/assets/website/design/frontend/images/t&p/Annual%20Report%20with%20Index-00.pdf" className={location.pathname === "/Annual Report" ? "active-menu" : ""}>Annual Report 2017-2018 </a>
                    </li>
                    <li>
                        <a href="http://ucetvbu.ac.in/assets/website/design/frontend/images/t&p/AnnualReport20182019.pdf" className={location.pathname === "/Annual Report" ? "active-menu" : ""}>Annual Report 2018-2019 </a>
                    </li>
                    
                    <li>
                        <Link to="/aicte" className={location.pathname === "/aicte" ? "active-menu" : ""}>AICTE</Link>
                    </li>
                    
                    <li>
                        <Link to="/teqip" className={location.pathname === "/teqip" ? "active-menu" : ""}>TEQIP</Link>
                    </li>
                    <li>
                        <Link to="/nba" className={location.pathname === "/nba" ? "active-menu" : ""}>NBA</Link>
                    </li>
                    <li>
                        <Link to="/bog" className={location.pathname === "/bog" ? "active-menu" : ""}>BoG</Link>
                    </li>
                    <li>
                        <Link to="/Grievance Portal" className={location.pathname === "/Grievance Portal" ? "active-menu" : ""}>Grievance Portal</Link>
                    </li>
                    <li>
                        <Link to="/Placement Brochure" className={location.pathname === "/Placement Brochure" ? "active-menu" : ""}>Placement Brochure</Link>
                    </li>
                </ul>
            </li>

            <li>
                <Link to="/gallery" className={location.pathname === "/gallery" ? "active-menu" : ""}>Gallery</Link>
            </li>

            <li className={parentMenu === 'cyber' ? 'current-menu-item' : ''}>
                <Link to="/cyber" className={location.pathname === "/cyber" ? "active-menu" : ""}>Cyber Defense</Link>
            </li>

            
            
            
        </React.Fragment>
    );
}

export default MenuItems;