import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Image
import cyberImage from '../../assets/img/cyber/cyber-security.png';
import tabImg1 from '../../assets/img/about/tab1.jpg';
import tabImg2 from '../../assets/img/about/tab2.jpg';
import tabImg3 from '../../assets/img/about/tab3.jpg';

const CyberTab = () => {
  
    let tab1 = "Cyber Competency",
        tab2 = "Who Should Join",
        tab3 = "Prerequisite"
    const tabStyle = 'nav nav-tabs histort-part';

    return (
        <div className="rs-about gray-bg style1 pt-100 pb-100 md-pt-70 md-pb-70">
            <div className="container">
                <Tabs>
                    <div className="row align-items-center">
                        <div className="col-lg-6 padding-0 md-pl-14 md-pr-14 md-mb-30 relative">
                            <div className="img-part">
                                <img 
                                    src={cyberImage}
                                    alt="About Image" 
                                />
                            </div>
                            <TabList className={tabStyle}>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-banknote"></i></span>{tab1}</button>
                                </Tab>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-flower"></i></span>{tab2}</button>
                                </Tab>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-analysis"></i></span>{tab3}</button>
                                </Tab>
                            </TabList>
                        </div>
                        <div className="col-lg-6 pl-120 md-pl-14">
                            <TabPanel>
                                <div className="about-history">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Cyber Competency Challenges & Way Forward</h2>
                                        <div className="desc">
                                            ֍ Cybercrime/Attack costs the global economy about  1 trillion. <br></br>
                                            ֍ Cyber Engineering Resource gap is causing havoc <br></br>
                                            ֍ Cyber Competency needs re-definition and needs to add 27+ unique Skills & Knowledge paradigm
                                             World need 7 million Cyber Engineers by 2025 <br></br>
                                            ֍ New Education Policy clearly supports dual degree or certification in critical area like cyber defense
                                        </div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg1}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="about-mission">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Who Should Join Educational Journey?</h2>
                                        <div className="desc">
                                            Post Graduate Diploma in Cyber Defense is meant
                                            for Under Graduate, Graduate or Post Graduate with 
                                            good scripting skills in Python & shell programming. 
                                            We provide practical oriented blended course in online 
                                            classroom and Cyber Simulator based absolute hands on
                                            experience in advance cyber defense skiils. Normally, 
                                            we look for passionate learners who wish make great 
                                            career in cyber offense - defense world. If you are 
                                            passionate, we waive your minimum criteria!!!
                                        </div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg2}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="about-admin">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Prerequisite</h2>
                                        <div className="desc">
                                            ֍ Online Skill test in Basic Python & Shell Scripting <br></br>
                                            ֍ Under Graduate, Graduate, Post Graduate <br></br>
                                            ֍ Know your Customer Verification <br></br>
                                            ֍ Background Check <br></br>
                                            ֍ Statement of Purpose <br></br>
                                            ֍ Psychometric Test
                                        </div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg3}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>
        </div>
    );
}

export default CyberTab