import React from 'react';
import Slider from "react-slick";
import SinglePost from './SinglePost';

import blogImg1 from '../../assets/img/blog/style2/1.jpg';
const BlogPart = (props) => {

    const events = props.events;

    const blogSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                }
            },            
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <Slider {...blogSettings}>
                {
                    (events.length > 0 )?(

                        events.map( (events,key) => {

                            return(
                                <SinglePost 
                                    blogClass = 'blog-item'
                                    blogImage = {events.eventImage}
                                    blogCategory= ''
                                    blogTitle = {events.eventTitle}
                                    blogDesc = {events.eventContent}
                                    blogPublishedDate = {events.eventDateFrom}
                                    key = {key}
                                />
                            )

                        })

                    ):''
                }
                

            </Slider>
        </React.Fragment>
    );

}

export default BlogPart;