import React from 'react'
import SectionTitle from '../../components/Common/SectionTitle';
import AboutImage from '../../assets/img/about-us/about-us-1.jpg'
import Mukul from '../../assets/img/vice-chancellor/mukul.jpg'

const TextData = () => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-lg-10 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="Message from Desk of Registrar"
                                titleClass="title mb-16"
                                title=""
                                descClass="mb-22" //bold-text
                                description="Education is undergoing great changes, integrating with the information superhighway. Innovations in communication and technology have enabled the educational sector to expand its realms in providing education to a wider range of students around the globe. The same effects of globalization on education have foreseen rapid changes day in and day out within learning systems across the world, change in the roles of students and teachers and producing a shift in society from industrialization towards an information-based society. Today, our educational world has a panorama of technology that can be used to boost learning for students. An ideal education ambiance can be visualized only when both the technology and teacher will be considered indispensable to each other. At UCET, It is a matter of great satisfaction that we have been able to balance between technology and teacher. UCET is committed for promoting excellence through holistic teaching-learning and research processes aimed at unleashing the creative potential of every student, thus enabling them to become catalysts of social and economic transformation necessary for a just, human and peaceful world. Our belief that goals can be best achieved through transparency, democratic values, equity, accountability and accessibility in an atmosphere that fosters intellectual freedom. It is our constant endeavour to actively ingrain these values into the day-to-day functioning of the university I wish lots of good luck to all."
                                secondDescClass="desc"
                                secondDescription=""
                                signature = "Thanks & Regards, <br/>(Dr.Birendra Kumar Gupta) <br/> Desk of Registrar <br/> Vinoba Bhave University <br/> Hazaribag-825301"
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                    <div className="col-lg-2 mt--24 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        {/* <div className="img-part"> */}
                            {/* <img className="about-main" src={Mukul} alt="About Image" /> */}
                        {/* </div> */}
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500} }>
                            Dr. Ajit Kumar Sinha
                        </div>
                        <div style={ {textAlign : 'center', padding:'0px',fontSize:'16px',fontWeight:500,color:'#6c8391'} }>
                            E-Mail :pvc@vbu.ac.in <br/>
                            Mob: 9470303568
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TextData